import { useEffect, useState } from "react";
import useDocumentVisibility from "./useDocumentVisibility";
import { Message } from "./useRoomMessages";

interface State {
    unreadCount: number;
}

const useUnreadMessages = (messages: Message[]): State => {
    const { isVisible } = useDocumentVisibility();
    const [newestMessageId, setNewestMessageId] = useState<string>(messages[0]?.messageId);
    const [state, setState] = useState<State>({ unreadCount: 0 });

    useEffect(() => {
        const newMessageId = messages[0]?.messageId;
        if (!newestMessageId) {
            setNewestMessageId(newMessageId);
            return
        };
        if (isVisible) {
            setNewestMessageId(newMessageId);
            setState({ unreadCount: 0 });
            return;
        }

        const unreadCount = messages.findIndex(({ messageId }) => messageId === newestMessageId);
        if (!unreadCount) return;

        const tooManyNewMessages = unreadCount < 0;
        if (tooManyNewMessages) {
            setState(state => ({ unreadCount: state.unreadCount + 1 }));
            return;
        }

        setState({ unreadCount });
    }, [isVisible, newestMessageId, messages])

    return state;
}

export default useUnreadMessages;