import { Box, Form, TextInput, Button } from 'grommet';
import { Upgrade } from 'grommet-icons';
import React, { useState } from 'react';

interface Props {
    onSubmit: (text: string) => void;
}

const Composer: React.FC<Props> = ({ onSubmit }) => {
    const [text, setText] = useState('');
    const handleSubmit = () => {
        if (!text.length) return;
        onSubmit(text);
        setText('')
    }

    return (
        <Box fill="horizontal"
            pad={{horizontal: "small", vertical: 'medium'}}
        >
            <Form onSubmit={handleSubmit}>
                <Box
                    direction="row"
                    align="center"
                    round="medium"
                    elevation="small"
                    pad={{ horizontal: 'small' }}
                    background="light-1"
                >
                    <TextInput
                        placeholder="Aa"
                        size="small"
                        plain
                        name="text"
                        value={text}
                        onChange={event => setText(event.target.value)}
                    />
                    <Button
                        onClick={handleSubmit}
                        icon={<Upgrade color="neutral-2" />}
                    />
                </Box>
            </Form>
        </Box>
    )
}

export default Composer;