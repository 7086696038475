import { useEffect, useState } from 'react';
import { store } from '../services/firebase';

export const businessRef = (businessId: string) => store.collection('businesses').doc(businessId)

export interface Activity {
    id: string;
    name: string;
    going: {
        [userId: string]: string;
    }
}

export interface Business {
    businessId: string;
    name: string;
    admins?: {
        [key: string]: {
            userId: string;
        }
    };
    mainRoomId: string;
}

interface BusinessState {
    business?: Business;
}

interface BusinessActions {
}

const useBusiness = (businessId: string) => {
    const [state, setState] = useState<BusinessState>();
    useEffect(() => {
        const loadBusiness = async () => {
            const businessDoc = await businessRef(businessId).get();
            setState(state => ({ ...state, business: (businessDoc.data() as Business) }));
        }

        loadBusiness();
    }, [businessId]);

    const hook: BusinessState & BusinessActions = {
        ...state
    }

    return hook;
}

export default useBusiness;