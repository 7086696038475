import { Text, Box, Header, Button, Form, RadioButtonGroup, TextInput } from 'grommet';
import { Close } from 'grommet-icons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gradientBackground } from '../../../components/AppContainer/AppContainer';
import { BusinessRoomContext } from '../../../context/BusinessRoomContext';
import { BusinessRoomMessagesContext } from '../../../context/BusinessRoomMessagesContext';
import { UserContext } from '../../../context/UserContext';
import { Activity } from '../../../hooks/useBusiness';
import { logEvent } from '../../../services/analyticsService';

const activities: Activity[] = [
    { id: 'food', name: 'go for dinner', going: {} },
    { id: 'drinks', name: 'have drinks tonight', going: {} },
    { id: 'tour', name: 'join the walking tour', going: {} },
    { id: 'custom', name: '(write your own)', going: {} },
];

const NewActivity: React.FC = () => {
    const history = useHistory();
    const { user } = useContext(UserContext);
    const { room } = useContext(BusinessRoomContext);
    const { roomId, businessId} = room || {};
    const { sendUserActivityMessage } = useContext(BusinessRoomMessagesContext);
    const [activity, setActivity] = useState<Activity>(activities[0]);
    const text = `${user.displayName} would like to ${activity.name}`;
    const canSubmit = () => {
        if (activity.id !== 'custom') return true;
        if (activity.name === activities[3].name) return false;
        if (activity.name.length < 6) return false;
        if (activity.name.length > 60) return false;
        return true;
    }
    const postToChat = () => {
        const a: Activity = {
            ...activity,
            going: {
                [user.uid]: user.displayName || ''
            }
        }
        sendUserActivityMessage(text, { activity: a });
        logEvent('complete_create_activity', { activityId: a.id, roomId, businessId });
        history.push(`/b/${businessId}/r/${roomId}`)
    }
    
    const cancel = () => {
        logEvent('cancel_create_activity', { businessId, roomId });
        history.push(`/b/${businessId}/r/${roomId}`);
    }

    useEffect(() => {
        if (!roomId) return;
        logEvent('start_create_activity', { businessId, roomId })
    }, [businessId, roomId]);
    
    if (!room) return null;
    
    return (
        <Box fill background={gradientBackground}>
            <Header pad={{ bottom: 'xsmall' }}>
                <Box
                    fill
                    justify="between"
                    background="light-1"
                    direction="row"
                    align="center"
                    elevation="xsmall"
                    pad="medium"
                >
                    <Text>
                        Make a plan
                    </Text>

                    <Button
                        icon={<Close />}
                        onClick={cancel}
                    />
                </Box>
            </Header>
            <Box fill="vertical" pad="medium" gap="medium">
                <Box flex="grow" pad={{ horizontal: "medium" }} gap="small">
                    <Text size="small">Preview</Text>
                    <Box
                        background="secondary"
                        pad="medium"
                        round="medium"
                        style={{ maxWidth: '80%' }}
                        alignSelf="center"
                    >
                        <Text color="white">{text}</Text>
                    </Box>
                </Box>

                <Box
                    background="light-1"
                    round
                    pad="medium"
                    fill
                    elevation="small"
                >
                    <Box fill>
                        <Form onSubmit={postToChat}>
                            <RadioButtonGroup
                                name="activity"
                                value={activity.id}
                                options={activities.map(({ id, name }) => ({ label: name, value: id }))}
                                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                    const selected = activities.find(({ id }) => id === target.value);
                                    setActivity(selected || activities[0]);
                                }}
                            />
                            {
                                activity.id === 'custom' &&
                                <Box pad={{ vertical: 'medium' }}>
                                    <TextInput
                                        maxLength={60}
                                        name="custom"
                                        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                            const custom: Activity = { id: 'custom', name: target.value, going: {} };
                                            setActivity(custom)
                                        }}
                                    />
                                </Box>
                            }
                        </Form>
                    </Box>
                    <Button
                        disabled={!canSubmit()}
                        primary
                        label="Send to chat"
                        onClick={postToChat}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default NewActivity;