import { Text, Box, Footer, Button } from 'grommet';
import React, { useContext } from 'react';
import { BusinessRoomContext } from '../../../context/BusinessRoomContext';
import { BusinessRoomMessagesContext } from '../../../context/BusinessRoomMessagesContext';
import { UserContext } from '../../../context/UserContext';
import { Activity } from '../../../hooks/useBusiness';
import { Message } from '../../../hooks/useRoomMessages';
import { logEvent } from '../../../services/analyticsService';

interface Props {
    messages: Message[];
    userId: string;
}

const datetime = (datetime: string) => {
    const date = new Date(datetime);
    const options = {
        hour: 'numeric',
        minute: 'numeric'
    };
    return Intl.DateTimeFormat('default', options).format(date);
}

interface DBMProps {
    message: Message;
    earlierMessage?: Message;
}
const formatDate = (date: Date, options: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat(undefined, options).format(date);
}
const buildFormatOptions = (messageDate: Date) => {
    const includeYear = new Date().getUTCFullYear() !== messageDate.getUTCFullYear();
    const includeMonth = new Date().getUTCMonth() !== messageDate.getUTCMonth();
    return {
        weekday: 'short',
        day: 'numeric',
        month: includeMonth ? 'short' : undefined,
        year: includeYear ? 'numeric' : undefined
    }
}
const formatMessageDate = (message: Message) => {
    const date = new Date(message.createdAt);
    return formatDate(date, buildFormatOptions(date));
}
const DateBetweenMessages: React.FC<DBMProps> = ({ message, earlierMessage }) => {
    const buildFormattedDate = () => {
        if (!earlierMessage) {
            return formatMessageDate(message);
        }
        const previousMessageHasDifferentDate = formatMessageDate(earlierMessage) !== formatMessageDate(message);
        if (previousMessageHasDifferentDate) {
            return formatMessageDate(message);
        }

        return null;
    }
    const date = buildFormattedDate()
    if (!date) return null

    return (
        <Box fill="horizontal" align="center" pad="small">
            <Text size="xsmall" color="dark-3">
                {date}
            </Text>
        </Box>
    );
}

const UserMessageTextBox: React.FC<{ message: Message, isOwnMessage: boolean }> = ({ message, isOwnMessage }) => {
    return (
        <Box
            background={isOwnMessage ? "secondary" : "light-4"}
            pad="medium"
            round="medium"
            style={{ maxWidth: '80%' }}
            alignSelf={isOwnMessage ? 'end' : 'start'}
        >
            <Text color={isOwnMessage ? "white" : "text"}>{message.text}</Text>
        </Box>
    )
}

const SystemMessageTextBox: React.FC<{ message: Message, isOwnMessage: boolean }> = ({ message, isOwnMessage }) => {
    return (
        <Box
            round="xsmall"
            alignSelf='center'
            pad="small"
        >
            <Text size="xsmall" color="dark-3">
                {message.text}
            </Text>
        </Box>
    )
}

const UserActivityMessageTextBox: React.FC<{ message: Message, isOwnMessage: boolean }> = ({ message, isOwnMessage }) => {
    const { sendUserActivityMessage } = useContext(BusinessRoomMessagesContext);
    const { room } = useContext(BusinessRoomContext);
    const { user } = useContext(UserContext);
    if (!room) return null;
    if (!message.meta?.activity) return null;
    
    const { businessId, roomId } = room;
    const { activity } = message.meta;
    
    const canAttend = () => {
        const going = activity.going || {};
        return !going[user.uid];
    }
    const meToo = () => {        
        if (!canAttend()) return;

        const a: Activity = {
            ...activity,
            going: {
                ...activity.going,
                [user.uid]: user.displayName || ''
            }
        }
        const text = `${user.displayName} and ${message.text}`;
        sendUserActivityMessage(text, { activity: a });
        logEvent('join_activity', { activityId: a.id, roomId, businessId })
    }

    return (
        <Box
            background="light-1"
            pad="medium"
            round="medium"
            fill="horizontal"
            elevation="small"
        >
            <Text>
                {message.text}
            </Text>
            {
                canAttend() && (
                    <Footer justify="end">
                        <Button primary size="small" label="me too!" onClick={meToo} />
                    </Footer>
                )
            }
        </Box>
    )
}

const MessageTextBox: React.FC<{ message: Message, isOwnMessage: boolean }> = ({ message, isOwnMessage }) => {
    if (message.type === 'user') return <UserMessageTextBox message={message} isOwnMessage={isOwnMessage} />
    if (message.type === 'user-activity') return <UserActivityMessageTextBox message={message} isOwnMessage={isOwnMessage} />
    if (message.type === 'system') return <SystemMessageTextBox message={message} isOwnMessage={isOwnMessage} />

    return null;
}

interface MAProps {
    message: Message;
    earlierMessage?: Message;
    isOwnMessage: boolean;
}
const MessageAuthor: React.FC<MAProps> = ({ message, earlierMessage, isOwnMessage }) => {
    if (message.type === 'system') return null;

    if (
        message.userId === earlierMessage?.userId &&
        earlierMessage.type === 'user' &&
        formatMessageDate(message) === formatMessageDate(earlierMessage)
    ) {
        return null;
    }

    return (
        <Box direction="row" gap="small" alignSelf={isOwnMessage ? 'end' : 'start'}>
            {
                !isOwnMessage && message.type === 'user' &&
                (
                    <Text weight="bold" size="xsmall" color="secondary">
                        {message.userName}
                    </Text>
                )
            }
            <Text size="xsmall" color="dark-3">
                {datetime(message.createdAt)}
            </Text>
        </Box>
    )
}

const isOwnMessage = (message: Message, userId: string) => userId === message.userId;
const differentAuthor = (message: Message, earlierMessage?: Message) => {
    return message.userId !== earlierMessage?.userId
        && message.type === 'user'
}
const Messages: React.FC<Props> = ({ messages, userId }) => {
    // messages are ordered as mostRecent first, or index 0;
    return (
        <Box
            overflow={{ vertical: 'auto' }}
            fill="vertical"
            justify="end"
        >
            <Box
                pad="small"
                direction="column-reverse"
                overflow={{ vertical: 'auto' }}
            >
                {messages.map((message, i) => (
                    <Box
                        style={{ flex: 'none' }}
                        key={message.messageId + i}
                        pad="xsmall"
                        margin={{
                            top: differentAuthor(message, messages[i + 1]) ? "small" : 'none'
                        }}
                    >
                        <DateBetweenMessages message={message} earlierMessage={messages[i + 1]} />
                        <MessageAuthor
                            message={message}
                            earlierMessage={messages[i + 1]}
                            isOwnMessage={isOwnMessage(message, userId)}
                        />
                        <MessageTextBox
                            message={message}
                            isOwnMessage={isOwnMessage(message, userId)}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default Messages;