import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface Props {
    onSignedOut: () => void;
}

const SignOut: React.FC<Props> = ({ onSignedOut }) => {
    const { signOut } = useAuth();
    const history = useHistory();
    useEffect(() => {
        const out = async () => {
            await signOut();
            history.push('/signin');
        }
        out()
    }, [signOut, history]);
    return <b>Signing out...</b>;
};
export default SignOut;