import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AppContainer from './components/AppContainer/AppContainer';
import usePageViews from './hooks/usePageView';
import SignIn from './pages/SignIn/SignIn';
import SignOut from './pages/SignOut/SignOut';
import PrivateRoute from './routes/PrivateRoute';
import BusinessRoomRoutes from './screens/Business/BusinessRoomRoutes';
import BusinessRoutes from './screens/Business/BusinessRoutes';

const App = () => {
  const history = useHistory();
  usePageViews();
  return (
    <AppContainer>
      <Switch>
        <Route exact path="/signin">
          <SignIn onSignedIn={from => { history.push(from) }} />
        </Route>
        <Route exact path="/signout">
          <SignOut onSignedOut={() => { history.push('/signin') }} />
        </Route>
        <PrivateRoute path={`/b/:businessId/r/:roomId`}>
          <BusinessRoomRoutes />
        </PrivateRoute>
        <PrivateRoute path="/b/:businessId">
          <BusinessRoutes />
        </PrivateRoute>
        <Route exact path="/r/whats-up-lisbon">
          <Redirect to="/b/lisboacentralhostel/r/main" />
        </Route>
        <Route exact path="/r/lisboacentralhostel">
          <Redirect to="/b/lisboacentralhostel/r/main" />
        </Route>
      </Switch>
    </AppContainer>
  )
}
export default App;
