import { Image, Box, Button, Main, Text, Heading, Anchor, Paragraph, Card, CardBody, Form, FormField, Layer } from 'grommet';
import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { gradientBackground } from '../../components/AppContainer/AppContainer';
import peopleImage from './people.jpg'
import activitiesImage from './activities.jpg'
import useRoom from '../../hooks/useRoom';
import { FormClose } from 'grommet-icons';

interface State {
    processing: boolean;
    error?: string;
}

interface Props {
    onSignedIn: (from: string) => void;
}

interface LocationState {
    params: {
        [prop: string]: string;
    },
    path: string;
    url: string;
}
interface CardProps {
    onClick: () => void;
    title: string;
    text: string;
    img: string;
}

const OnboardingCard: React.FC<CardProps> = ({onClick, title, text, img}) => {
    return (
        <Box pad="large">
            <Card fill elevation="large" background="white" round="large">
                <CardBody height="medium">
                    <Image fit="cover" src={img} />
                </CardBody>
                <Box pad={{vertical: "large", horizontal: "medium"}} gap="medium">
                    <Heading textAlign="center" margin={{ vertical: 'medium' }} style={{fontSize:'3rem'}}>
                        {title}
                    </Heading>
                    <Paragraph fill textAlign="center" margin={{ top: 'none' }} style={{color: "#707070"}}>
                        {text}
                    </Paragraph>
                    <Button primary label="next" onClick={onClick} />
                </Box>
            </Card>
        </Box>
    )
}

interface JoinRoomProps {
    businessId: string;
    roomId: string;
    onUsername: (username: string) => {};
}

const JoinRoom: React.FC<JoinRoomProps> = ({onUsername, businessId, roomId}) => {
    const { room } = useRoom(businessId, roomId);

    if (!room) return null;

    const setName = async ({ value }: any) => {
        const username: string = (value.username || "").trim()
        if (username.length) {
            onUsername(username);
        }
    }

    return (
        <Box pad="medium" fill justify="start">
            <Text size="large" textAlign="center" margin={{ bottom: 'medium' }}>
                Join {room.name} in {room.businessName}
            </Text>
            <Card elevation="large" background="white" round="large" pad="large">
                <Heading level="2" margin={{top:"none"}}>Hi 👋 What's your name?</Heading>
                <Form onSubmit={setName}>
                    <FormField
                        maxLength={20}
                        name="username"
                        required
                        autoFocus
                        type="text" />
                    <Button type="submit" margin={{ vertical: "medium" }} fill="horizontal" primary label="join" />
                </Form>
            <Paragraph fill textAlign="center" size="small">
                    By proceeding, you confirm that you agree to our
                    {" "}
                    <Anchor
                        href="/privacy-policy.html"
                        target="_blank"
                    >
                        Privacy Policy
                    </Anchor>
                </Paragraph>
            </Card>
        </Box>
    )
}

interface ErrorHandlerProps {
    error?: string;
    onClose: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({error, onClose}) => {
    if (!error) return null;

    return (
        <Layer
            onEsc={onClose}
            plain                    
        >
            <Box fill background="#ffffff99" onClick={onClose} pad="medium">
                <Box
                    align="center"
                    direction="row"
                    gap="medium"
                    justify="between"
                    round="medium"
                    elevation="medium"
                    pad={{ vertical: 'medium', horizontal: 'large' }}
                    background="status-error"
                    >
                    <Text>
                        {error}
                    </Text>
                    <Button icon={<FormClose />} onClick={onClose} plain />
                </Box>
            </Box>
        </Layer>
    )
}

const SignIn: React.FC<Props> = ({ onSignedIn }) => {
    const { state } = useLocation<LocationState>();
    const from = state?.url || '/';
    const { businessId, roomId } = state?.params || {};
 
    const [cardIndex, setCardIndex] = useState(0);
    
    const [{ processing, error}, setState] = useState<State>({ processing: false });
    const {
        user,
        isLoading,
        signInAnonymously,
        setDisplayName
    } = useAuth();

    if (isLoading || user) return null;
    
    const start = async (username: string) => {
        if (processing) return;

        setState({ processing: true, error: undefined });
        const user = await signInAnonymously();
        if (!user) {
            setState({
                processing: false,
                error: 'Unable to proceed. Please try again.'
            })
            return;
        }
        await setDisplayName(username);
        onSignedIn(from);
    }

    const onClose = () => {
        setState(state => ({ ...state, processing: false, error: undefined }))
    }

    return (
        <Main pad="small" justify="center" align="center" background={gradientBackground}>
            <ErrorHandler error={error} onClose={onClose} />
            {cardIndex === 0 && <OnboardingCard 
                img={peopleImage}
                title="People"
                text="Find new people to do things with"
                onClick={() => setCardIndex(1)}
                />}
            {cardIndex === 1 && <OnboardingCard 
                img={activitiesImage}
                title="Activities"
                text="Find new things to do with people"
                onClick={() => setCardIndex(2)}
            />}
            {cardIndex === 2 && <JoinRoom 
                businessId={businessId}
                roomId={roomId}
                onUsername={start}
            />}
        </Main>
    );
};
export default SignIn;