const cloudMessagingKeys = {
    diloDev: 'BL7scaQ1pe6p_MBaoO7VKZYec_aN4m8e-mOwE4FT8Xonh3m2gzRLlqBNFRFSb7k5Socq0dB9Ci1lII8WVrarPbs',
    diloProd: 'BKEq0dDylS7VViQThisOnQD_KIh6TKTZs6753M-fdWBIsJ1y7Qt7l8VPwWdIWkiFxqRdARlNPbjk24lF9UgFrwQ'
}
const firebaseConfig = {
    diloDev: {
        apiKey: "AIzaSyDC8ajeqJR30bdcguHXOPxSRlJo2sfY3Mg",
        authDomain: "dilo-dev.firebaseapp.com",
        databaseURL: "https://dilo-dev.firebaseio.com",
        projectId: "dilo-dev",
        storageBucket: "dilo-dev.appspot.com",
        messagingSenderId: "741989785052",
        appId: "1:741989785052:web:a742ad08b1a54d29b18319",
        measurementId: "G-V39JS7WLVT"
    },
    diloProd: {
        apiKey: "AIzaSyBZAMoaSRzlYoQLjByI3aKtq1mY6iyAIbc",
        authDomain: "dilo-prod.firebaseapp.com",
        databaseURL: "https://dilo-prod.firebaseio.com",
        projectId: "dilo-prod",
        storageBucket: "dilo-prod.appspot.com",
        messagingSenderId: "867014723206",
        appId: "1:867014723206:web:54bd7825f0aa72d81fb307",
        measurementId: "G-RJ6WDV526D"
    }
}

export const getFirebaseConfig = () => {
    const { REACT_APP_FIREBASE_PROJECT_ID } = process.env;
    if (REACT_APP_FIREBASE_PROJECT_ID === 'dilo-prod') {
        return firebaseConfig.diloProd;
    }
    return firebaseConfig.diloDev;
}

export const getVapidKey = () => {
    const { REACT_APP_FIREBASE_PROJECT_ID } = process.env;
    if (REACT_APP_FIREBASE_PROJECT_ID === 'dilo-prod') {
        return cloudMessagingKeys.diloProd;
    }
    return cloudMessagingKeys.diloDev;
}