import { useEffect, useState } from 'react';
import { businessRef } from './useBusiness';
import firebase from 'firebase/app';
import { logEvent } from '../services/analyticsService';

export const roomRef = (businessId: string, roomId: string) => businessRef(businessId).collection('rooms').doc(roomId)

interface Member {
    userId: string;
    lastActive: string;
    username?: string;
    isActive: boolean;
    notificationsToken?: string;
}
export interface Room {
    roomId: string;
    businessId: string;
    businessName: string;
    createdAt: string;
    name: string;
    members: {
        [userId: string]: Member;
    }
}

interface RoomState {
    room?: Room;
}

interface RoomActions {
    joinRoom: (user: firebase.UserInfo) => void;
    leaveRoom: (user: firebase.UserInfo) => void;
    isUserInRoom: (userId: string) => boolean;
    hasUserEnabledNotifications: (userId: string) => boolean;
    updateUserNotificationsToken: (userId: string, notificationsToken?: string) => void;
}

export type RoomHook = RoomState & RoomActions;

const useRoom = (businessId: string, roomId: string) => {
    const [state, setState] = useState<RoomState>({
        room: undefined
    });
    const { room } = state;
    useEffect(() => {
        const load = async () => {            
            const doc = await roomRef(businessId, roomId).get();
            setState(state => ({ ...state, room: (doc.data() as Room) }));
        }

        load();
    }, [businessId, roomId]);

    const updateMember = (user: firebase.UserInfo) => {
        if (!room) return;
        const update = {
                userId: user.uid,
                displayName: user.displayName,
                lastActive: new Date().toISOString()
        }
        roomRef(businessId, roomId).set({
            members: {
                [user.uid]: update
            }
        }, { merge: true });
    }

    const joinRoom = (user: firebase.UserInfo) => {
        if (!room) return;
        updateMember(user);
        logEvent("join_room", { businessId, roomId });        
    }

    const leaveRoom = (user: firebase.UserInfo) => {
        if (!room) return;
        updateMember(user);
        logEvent("leave_room", { businessId, roomId });
    }

    const isUserInRoom = (userId: string) => {
        if (!room) return false;
        return Object.keys(room.members).includes(userId);
    }

    const hasUserEnabledNotifications = (userId: string) => {
        if (!room) return false;
        const member = room.members[userId];
        if (!member) return false;
        const { notificationsToken } = member;
        return !!notificationsToken?.length;
    }

    const updateUserNotificationsToken = (userId: string, notificationsToken?: string) => {
        if (!businessId) return;
        if (!roomId) return;
        const path = 'members.' + userId + '.notificationsToken';
        const value = notificationsToken || firebase.firestore.FieldValue.delete();
        roomRef(businessId, roomId).update({ [path]: value });
    }

    const hook: RoomState & RoomActions = {
        ...state,
        joinRoom,
        leaveRoom,
        isUserInRoom,
        hasUserEnabledNotifications,
        updateUserNotificationsToken,
    }

    return hook;
}

export default useRoom;