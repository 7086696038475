import React, { useEffect, useRef, useState } from 'react'
import useDocumentVisibility from '../../../hooks/useDocumentVisibility';
import { Message } from '../../../hooks/useRoomMessages';

interface Props {
  mostRecentMessage?: Message;
  userId: string;
}

const PlaySoundWhenDocumentIsHidden: React.FC<Props> = ({ mostRecentMessage, userId }) => {
  const { isVisible } = useDocumentVisibility();
  const audioEl = useRef<HTMLAudioElement>(null);
  const [lastPlayedMessageId, setLastPlayedMessageId] = useState<string>();
  
  useEffect(() => {
    if (!audioEl.current) return;
    if (!mostRecentMessage) return;
    if (mostRecentMessage.userId === userId) return;
    if (lastPlayedMessageId === mostRecentMessage.messageId) return;
    if (isVisible) {
      setLastPlayedMessageId(mostRecentMessage.messageId);
      return;
    };

    audioEl.current.play();
    setLastPlayedMessageId(mostRecentMessage.messageId);
  }, [isVisible, mostRecentMessage, userId, lastPlayedMessageId])

  return <audio ref={audioEl} src={process.env.PUBLIC_URL + '/sounds/new_message.mp3'} />
}

export default PlaySoundWhenDocumentIsHidden;
