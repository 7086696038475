import React, { useEffect } from 'react';
import { Message } from '../../../hooks/useRoomMessages';
import useUnreadMessages from '../../../hooks/useUnreadMessagesCount';

interface Props {
    messages: Message[];
}

const ChangeTitleWhenUnreadMessages: React.FC<Props> = ({ messages }) => {
    const { unreadCount } = useUnreadMessages(messages);

    useEffect(() => {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        if (!unreadCount) {
            document.title = 'people - dilo';
            favicon.href = process.env.PUBLIC_URL + '/favicon.ico'
        }
        if (unreadCount === 1) {
            document.title = 'New message on dilo';
            favicon.href = process.env.PUBLIC_URL + '/favicon-one.ico'
        }
        
        if (unreadCount > 1) {
            document.title = `${unreadCount} new messages on dilo`;
            favicon.href = process.env.PUBLIC_URL + '/favicon-one-plus.ico'
        }        
    }, [unreadCount]);

    return null;
}

export default ChangeTitleWhenUnreadMessages;