import React from 'react';
import { RoomMessagesHook } from '../hooks/useRoomMessages';

export const BusinessRoomMessagesContext = React.createContext<RoomMessagesHook>({
    messages: [],
    sendSystemMessage: () => {},
    sendUserMessage: () => {},
    sendUserActivityMessage: () => {},
});

