import { useEffect, useState } from 'react';
import { Activity } from './useBusiness';
import firebase from 'firebase/app';
import { logEvent } from '../services/analyticsService';
import { getMessaging } from '../services/firebase';
import { Room, roomRef } from './useRoom';

export const roomMessagesRef = (businessId: string, roomId: string) => roomRef(businessId, roomId).collection('messages')

type MessageType = 'user' | 'system' | 'user-activity';
interface ActivityMessageMeta {
    activity: Activity;
}
type MessageMeta = ActivityMessageMeta;
export interface Message {
    roomId: string;
    messageId: string;
    userId: string;
    userName: string;
    text: string;
    createdAt: string;
    type: MessageType;
    timestamp: firebase.firestore.FieldValue;
    meta?: MessageMeta
}

interface RoomMessagesState {
    messages: Message[];
}

interface RoomMessagesActions {
    sendUserActivityMessage: (text: string, activityMeta: ActivityMessageMeta) => void
    sendUserMessage: (text: string) => void
    sendSystemMessage: (text: string) => void
}

export type RoomMessagesHook = RoomMessagesState & RoomMessagesActions;

const sortByCreatedAt = <T extends { createdAt: string }>(objects: T[]): T[] => {
    return objects.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
}

const useRoomMessages = (
    { uid: userId, displayName }: firebase.UserInfo,
    room: Room | undefined
) => {
    const { businessId, roomId } = room || {};
    const [state, setState] = useState<RoomMessagesState>({
        messages: [],
    });

    useEffect(() => {
        if (!businessId) return;
        if (!roomId) return;
        const unsubscribe = roomMessagesRef(businessId, roomId)
            .orderBy('createdAt', 'desc')
            .limit(40)
            .onSnapshot(querySnapshot => {
                const messages: Message[] = []
                querySnapshot.forEach(doc => messages.push(doc.data() as Message));
                setState(state => ({
                    ...state,
                    messages: sortByCreatedAt<Message>(messages)
                }));
            });
        
        const messaging = getMessaging()
        let stopMessaging: firebase.Unsubscribe;
        if (messaging) {
            stopMessaging = messaging.onMessage((payload) => {
                console.log('received message on foreground', payload)
            });
        }

        return () => {
            unsubscribe();
            if (stopMessaging) {
                stopMessaging();
            }
        }
    }, [userId, businessId, roomId]);

    const sendMessage = (text: string, type: MessageType = 'user', meta?: MessageMeta) => {
        if (!text.length) return;
        if (!businessId) return;
        if (!roomId) return;

        const messageRef = roomMessagesRef(businessId, roomId).doc();
        const message: Message = {
            messageId: messageRef.id,
            createdAt: new Date().toISOString(),
            roomId,
            text,
            type,
            userId,
            userName: displayName || '',
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        }
        if (meta) {
            message.meta = meta;
        }
        messageRef.set(message);
    }
    
    const sendUserMessage = (text: string) => {
        sendMessage(text, 'user');
        logEvent("send_message", { businessId, roomId });
    }

    const sendUserActivityMessage = (text: string, activity: ActivityMessageMeta) => {
        sendMessage(text, 'user-activity', activity);
        logEvent("send_message", { businessId, roomId });
    }

    const sendSystemMessage = (text: string) => sendMessage(text, 'system');

    const hook: RoomMessagesHook = {
        ...state,
        sendUserMessage,
        sendUserActivityMessage,
        sendSystemMessage,
    }

    return hook;
}

export default useRoomMessages;