import React from 'react';
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom';
import { BusinessContext } from '../../context/BusinessContext';
import useBusiness from '../../hooks/useBusiness';

const BusinessRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const { businessId } = useParams<{ businessId: string }>();
  const { business } = useBusiness(businessId);
  if (!business) return null;

  return (
    <BusinessContext.Provider value={{ businessId, business }}>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`/b/${businessId}/r/${business.mainRoomId}`} />
        </Route>
        {/* <Route path={`${path}/r/:roomId`}>
          <BusinessRoomRoutes />
        </Route> */}
      </Switch>
    </BusinessContext.Provider>
  )
}

export default BusinessRoutes;