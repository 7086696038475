import React from 'react';
import { useLocation } from "react-router-dom";
import { analytics } from '../services/firebase'

const usePageViews = () => {
    const location = useLocation();
    React.useEffect(() => {
        analytics.logEvent('page_view', {
            page_path: location.pathname
        });
    }, [location]);
}

export default usePageViews;