import React from 'react';
import firebase from 'firebase/app';

interface UserContextState {
    user: firebase.UserInfo;
    userId: string;
}

export const UserContext = React.createContext<UserContextState>({
    userId: 'invalid',
    user: {
        uid: 'invalid',
        displayName: 'invalid',
        email: 'invalid',
        phoneNumber: 'invalid',
        photoURL: 'invalid',
        providerId: 'invalid'
    }
});

