import { Box, Button, Text } from 'grommet';
import React, { useContext, useState } from 'react';
import { isMobile, isChrome, isFirefox } from 'react-device-detect';
import { UserContext } from '../../../context/UserContext';
import useNotifications from '../../../hooks/useNotifications';
import { BusinessRoomContext } from '../../../context/BusinessRoomContext';

const supportsNotifications = () => {
    if (isMobile) return false
    return isChrome || isFirefox
}

const RequestNotificationPermissions = () => {
    const { user } = useContext(UserContext);
    const { hasUserEnabledNotifications, updateUserNotificationsToken } = useContext(BusinessRoomContext);
    const [notificationsEnabled, setNotificationsEnabled]  = useState(hasUserEnabledNotifications(user.uid));
    const handleNotificationsRequest = async () => {
        if (notificationsEnabled) {
            setNotificationsEnabled(false);
            return updateUserNotificationsToken(user.uid, undefined);
        }
        
        const token = await requestPermission();
        if (token) {            
            setNotificationsEnabled(true);
            updateUserNotificationsToken(token);
        }
    }
    const { requestPermission } = useNotifications();

    if (!supportsNotifications()) return null;

    if (notificationsEnabled) {
        return (
            <Box pad="medium" fill background="light-1" elevation="xsmall">
                <Button
                    size="small"
                    color="accent-4"
                    onClick={handleNotificationsRequest}
                    label="Turn notifications off"
                />
            </Box>
        )
    }

    return (
        <Box pad="medium" fill flex="shrink" background="light-1" elevation="xsmall">
            <Text textAlign="center" >
                Don't miss out on new plans!
            </Text>
            <Button
                margin="small"
                primary
                color="accent-4"
                onClick={handleNotificationsRequest}
                label="Turn notifications on"
            />
            <Text textAlign="center" size="small">
                You can easily turn them off
            </Text>
        </Box>
    )
}

export default RequestNotificationPermissions;