import { useEffect, useState } from "react";
import firebase from 'firebase/app';
import { auth } from "../services/firebase";
import { logEvent } from "../services/analyticsService";

interface AuthState {
    isLoading: boolean;
    user: firebase.UserInfo | null;
}
interface AuthActions {
    signInAnonymously: () => Promise<firebase.UserInfo | null>;
    signOut: () => Promise<void>;
    setDisplayName: (displayName:string) => Promise<void>;
}

const toUserInfo = (user: firebase.UserInfo) => ({
    displayName: user.displayName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    providerId: user.providerId,
    uid: user.uid,
})

const useAuth = () => {
    const [state, setState] = useState<AuthState>({
        isLoading: true,
        user: null
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => { 
            setState({
                isLoading: false,
                user
            });
        });
        return unsubscribe;
    }, []);

    const signInAnonymously = async () => {
        try {
            const { user } = await auth.signInAnonymously();
            if (!user) return null;
            logEvent("sign_in");
            const userInfo = toUserInfo(user);
            setState(state => ({...state, user: userInfo }));
            return userInfo;
        } catch(_) {
            return null;
        }
    }

    const signOut = () => {
        logEvent("sign_out");
        return auth.signOut()
    }

    const setDisplayName = async (displayName: string) => {
        if (!auth.currentUser) return;
        if (!state.user) {
            state.user = toUserInfo(auth.currentUser);
        }
        await auth.currentUser.updateProfile({ displayName })
        setState({...state, user: {...state.user, displayName }})
    }

    const hook: AuthState & AuthActions = {
        ...state,
        signInAnonymously,
        signOut,
        setDisplayName
    }

    return hook;
}

export default useAuth;