import { getVapidKey } from "../services/configService";
import { getMessaging } from '../services/firebase'
import { logEvent } from "../services/analyticsService";

interface NotificationsState {

}

interface NotificationActions {
    requestPermission: () => Promise<string|null>;
}

const useNotifications = () => {
    const requestPermission = async () => {
        const messaging = getMessaging();
        if (!messaging) return null;
        try {
            const result = await messaging.getToken({ vapidKey: getVapidKey() })
            logEvent("push_notifications_allowed");
            return result;
        } catch(error) {
            logEvent("push_notifications_denied");
        }
        return null;
    }
    
    const hook: NotificationsState & NotificationActions = {
        requestPermission
    }
    return hook;
}

export default useNotifications;