import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import useAuth from '../hooks/useAuth';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const { user, isLoading } = useAuth();

    if (isLoading) return null;

    return (
        <Route
            {...rest}
            render={({ match }) => {
                if (user) {
                    return (
                        <UserContext.Provider value={{ user, userId: user.uid }}>
                            {children}
                        </UserContext.Provider>
                    )
                }
                return (
                    <Redirect to={{
                        pathname: "/signin",
                        state: match
                    }}/>
                )
            }
        }/>
    );
}

export default PrivateRoute;