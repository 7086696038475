import React, { useState } from 'react'

interface State {
    isVisible: boolean;
    isHidden: boolean;
}

const buildState = () => ({
    isVisible: document.visibilityState === 'visible',
    isHidden: document.visibilityState === 'hidden',
})

const useDocumentVisibility = () => {
  const [state, setState] = useState<State>(buildState());
  
  React.useEffect(() => {
    const handleVisibilityChange = () => setState(buildState());
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  return state;
}

export default useDocumentVisibility;
