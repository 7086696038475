import React from 'react';
import { Business } from '../hooks/useBusiness';

interface BusinessContextState {
    business: Business;
    businessId: string;
}

export const BusinessContext = React.createContext<BusinessContextState>({
    business: {
        businessId: 'invalid',
        name: 'invalid',
        mainRoomId: 'invalid'
    },
    businessId: 'invalid'
});

