import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/messaging';
import { getFirebaseConfig } from './configService';

firebase.initializeApp(getFirebaseConfig());

export const auth = firebase.auth();

export const store = firebase.firestore();
export const analytics = firebase.analytics();
export const perf = firebase.performance();
export const getMessaging = () => {
    if (firebase.messaging.isSupported()) {
        return firebase.messaging();
    }
    return null;
}
