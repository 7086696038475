import firebase from 'firebase/app'
import { analytics } from './firebase';

type NotificationsEvents = 'push_notifications_allowed' | 'push_notifications_denied';
type UserEvents = 'sign_in' | 'sign_out';
type RoomEvents = 'send_message' | 'join_room' | 'leave_room';
type DownloadAppEvents = 'download_app_click';
type UserActivitiesEvents = 'start_create_activity'
    | 'cancel_create_activity'
    | 'complete_create_activity'
    | 'join_activity';
export type DiloEvent = UserEvents 
    | RoomEvents
    | NotificationsEvents
    | UserActivitiesEvents
    | DownloadAppEvents;

export const logEvent = (
    event: DiloEvent,
    eventParams?: { [key: string]: any },
    options?: firebase.analytics.AnalyticsCallOptions) => {
    analytics.logEvent(event, eventParams, options);
}