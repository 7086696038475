import React from 'react';
import { RoomHook } from '../hooks/useRoom';

export const BusinessRoomContext = React.createContext<RoomHook>({
    room: {
        roomId: 'invalid',
        name: 'invalid',
        businessId: 'invalid',
        businessName: 'invalid',
        createdAt: 'invalid',
        members: {}
    },
    isUserInRoom: () => false,
    hasUserEnabledNotifications: () => false,
    updateUserNotificationsToken: () => {},
    joinRoom: () => {},
    leaveRoom: () => {},
});

