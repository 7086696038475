import { Box, Footer, Header } from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import { gradientBackground } from '../../../components/AppContainer/AppContainer';
import { BusinessRoomContext } from '../../../context/BusinessRoomContext';
import { BusinessRoomMessagesContext } from '../../../context/BusinessRoomMessagesContext';
import { UserContext } from '../../../context/UserContext';
import ChangeTitleWhenUnreadMessages from './ChangeTitleWhenUnreadMessages';
import Composer from './Composer';
import Messages from './Messages';
import PlaySoundWhenDocumentIsHidden from './PlaySoundWhenDocumentIsHidden';
import RequestNotificationPermissions from './RequestNotificationPermissions';

const Room: React.FC = () => {
    const { user } = useContext(UserContext);
    const { room, joinRoom, leaveRoom, isUserInRoom } = useContext(BusinessRoomContext);
    const {
        messages,
        sendUserMessage,
        sendSystemMessage,
    } = useContext(BusinessRoomMessagesContext);

    const [joined, setJoined] = useState(false);
    const shouldSendMessage = !isUserInRoom(user.uid) && !!user.displayName;
    
    useEffect(() => {
        if (joined) return;
        if (shouldSendMessage) {
            sendSystemMessage(`${user.displayName} joined`)
        }
        setJoined(true);
        joinRoom(user);
        return () => {
            if (joined) {
                console.log('leaving')
                leaveRoom(user);
            }
        }
    }, [joined, setJoined, joinRoom, leaveRoom, user, sendSystemMessage, shouldSendMessage]);

    if (!room) return null;

    return (
        <Box fill background="light-3">
            <PlaySoundWhenDocumentIsHidden mostRecentMessage={messages[0]} userId={user.uid} />
            <ChangeTitleWhenUnreadMessages messages={messages} />
            <Header pad={{ bottom: 'xsmall' }} direction="column" gap="none">
                <RequestNotificationPermissions />
            </Header>
            <Messages messages={messages} userId={user.uid} />
            <Footer pad={{ bottom: "medium" }} background={gradientBackground}>
                <Composer onSubmit={sendUserMessage} />
            </Footer>
        </Box>
    )
}

export default Room;