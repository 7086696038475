import React, { useContext } from 'react';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { BusinessRoomContext } from '../../context/BusinessRoomContext';
import { BusinessRoomMessagesContext } from '../../context/BusinessRoomMessagesContext';
import { UserContext } from '../../context/UserContext';
import useRoom from '../../hooks/useRoom';
import useRoomMessages from '../../hooks/useRoomMessages';
import NewActivity from './Activities/NewActivity';
import Room from './Room/Room';

const BusinessRoomRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    const { businessId, roomId } = useParams<{ businessId: string, roomId: string }>();
    const roomHook = useRoom(businessId, roomId);
    const { user } = useContext(UserContext);
    const messagesHook = useRoomMessages(user, roomHook.room);

    if (!roomId) return null;
    if (!roomHook.room) return null;
    
    return (
      <BusinessRoomContext.Provider value={roomHook}>
        <BusinessRoomMessagesContext.Provider value={messagesHook}>
            <Switch>
              <Route exact path={path}>
                <Room />
              </Route>
              <Route path={`${path}/a/new`}>
                <NewActivity />
              </Route>
          </Switch>
        </BusinessRoomMessagesContext.Provider>
      </BusinessRoomContext.Provider>
    )
}

export default BusinessRoomRoutes;