import { Grommet, grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import React from 'react';

export const gradientBackground = {  image: "linear-gradient(147deg, #FFE53B 0%, #FF2525 74%)", opacity: true };
const colors = {
    secondary: '#ef8354',
    brand: {
        light: '#4f5d75',
        dark: '#ef8354'
    },
    focus: '#ef8354',
    background: 'light-1'
}

export const appTheme = deepMerge(grommet, {
    global: {
        colors
    }
})

const AppContainer: React.FC = ({ children }) => (
    <Grommet theme={appTheme} full style={{height: '100%'}}>
        {children}
    </Grommet>
)

export default AppContainer;